<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="@/assets/images/logo/logo_sob.svg"
            height="70"
            width="80"
            alt=""
            srcset=""
          >
          <img
            src="@/assets/images/logo/deno.svg"
            height="40"
            width="103"
            class="mt-1"
            alt=""
            srcset=""
          >
        </b-link>

        <b-card-title class="mb-1">
          Confirmation de l'adresse email
        </b-card-title>
        <b-card-text class="mb-2">
          Veuillez patienter durant la confirmation de votre compte
        </b-card-text>

        <div class="text-center">
          <b-spinner
            v-if="process"
            class="mr-1"
            variant="primary"
          />
        </div>

        <div class="divider my-2">
          <div class="divider-text text-primary font-weight-bold h5">
            SOBEMAP
          </div>
        </div>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      loaded: false,
      userEmail: null,
    }
  },
  computed: {
    ...mapGetters({
      process: 'confirmEmailProcess',
      error: 'confirmEmailError',
      success: 'confirmEmailSuccess',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.confirmEmail()
      },
    },
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation de compte',
            text: 'Email confirmé avec succès',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 2000)
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation de compte',
            text: val?.message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['confirmUserEmail']),
    async confirmEmail() {
      this.confirmUserEmail({
        id: this.$route.params.id,
        token: this.$route.params.token,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
